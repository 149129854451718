export const globalStyles = {
  colors: {
    default: {
      700: "linear-gradient(135deg, #f8faff, #fef7fd)",
    },
    gray: {
      700: "#1f2733",
    },
  },
  styles: {
    global: (props) => ({
      body: {
        overflowX: "hidden",
        bg: "#ffffff",
        fontFamily: "Helvetica, sans-serif",
        padding: 0,
        margin: 0,
        color: "gray.700",
      },
      html: {
        fontFamily: "Helvetica, sans-serif",
      },
      a: {
        color: "#000",
        textDecoration: "none",
      },
    }),
  },
};
