import { HStack, Text, useColorModeValue as mode } from "@chakra-ui/react";
export function formatPrice(value, opts = {}, style = true) {
  const { locale = "en-US", currency = "PLN" } = opts;

  var formatter = new Intl.NumberFormat(locale, {
    currency,
    style: "currency",
    maximumFractionDigits: 2,
  });

  return formatter.format(value > 0 ? value : 0);
}

export const PriceTag = (props) => {
  const { price, currency, salePrice, rootProps, priceProps, salePriceProps } = props;
  return (
    <HStack spacing="1" {...rootProps}>
      {salePrice && (
        <SalePrice {...salePriceProps}>
          {formatPrice(salePrice, {
            currency,
          })}
        </SalePrice>
      )}
      <Price isOnSale={!!salePrice} textProps={priceProps}>
        {formatPrice(price, {}, false)}
      </Price>
    </HStack>
  );
};
const Price = (props) => {
  const { isOnSale, children, textProps } = props;
  const defaultColor = mode("gray.700", "gray.400");
  const onSaleColor = mode("gray.400", "gray.700");
  const color = isOnSale ? onSaleColor : defaultColor;
  return (
    <Text as="span" fontWeight="medium" color={color} textDecoration={isOnSale ? "line-through" : "none"} {...textProps}>
      {children}
    </Text>
  );
};
const SalePrice = (props) => <Text as="span" fontWeight="semibold" color={mode("gray.800", "gray.100")} {...props} />;
