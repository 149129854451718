import { Spinner } from "@chakra-ui/react";

function Loading(props) {
  const styles = {
    div: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      height: "100%",
      width: "100%",
    },
  };

  return (
    <div style={styles.div}>
      <Spinner thickness="4px" speed="0.65s" size="xl" />
    </div>
  );
}

export default Loading;
