import axios from "axios";
import config from "API/config.json";

axios.defaults.headers.common["key"] = config.key;

let cooldownTimeout;
let lastRequestTime = 0;

const cancelCooldown = () => {
  if (cooldownTimeout) {
    clearTimeout(cooldownTimeout);
    cooldownTimeout = undefined;
  }
};

const startCooldown = () => {
  cooldownTimeout = setTimeout(() => {
    cooldownTimeout = undefined;
  }, 1000);
};

export const getRequest = async (url, headers) => {
  try {
    const currentTime = Date.now();
    const timeElapsed = currentTime - lastRequestTime;

    const response = await axios.get(`${config.host}/${url}`, { headers });
    lastRequestTime = Date.now(); // Update last request time
    startCooldown(); // Start the cooldown period
    return response.data;
  } catch (error) {
    console.error("Error making GET request:", error);
    throw error;
  }
};

export const postRequest = async (url, data = {}, headers = {}) => {
  try {
    const currentTime = Date.now();
    const timeElapsed = currentTime - lastRequestTime;

    const response = await axios.post(`${config.host}/${url}`, data, { headers });
    lastRequestTime = Date.now(); // Update last request time
    startCooldown(); // Start the cooldown period
    return response.data;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
