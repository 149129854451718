import { Box, Heading, Text, Flex } from "@chakra-ui/react";
import { CheckCircleIcon, InfoIcon, WarningTwoIcon, CloseIcon } from "@chakra-ui/icons";

export const Success = ({ children, headline }) => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <CheckCircleIcon boxSize={"50px"} color={"green.500"} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        {headline}
      </Heading>
      <Text color={"gray.500"}>{children}</Text>
    </Box>
  );
};

export const Info = ({ children, headline }) => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <InfoIcon boxSize={"50px"} color={"blue.500"} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        {headline}
      </Heading>
      <Text color={"gray.500"}>{children}</Text>
    </Box>
  );
};

export const Warning = ({ children, headline }) => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <WarningTwoIcon boxSize={"50px"} color={"orange.300"} />
      <Heading as="h2" size="xl" mt={6} mb={2}>
        {headline}
      </Heading>
      <Text color={"gray.500"}>{children}</Text>
    </Box>
  );
};

export const Error = ({ children, headline }) => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Box display="inline-block">
        <Flex flexDirection="column" justifyContent="center" alignItems="center" bg={"red.500"} rounded={"50px"} w={"55px"} h={"55px"} textAlign="center">
          <CloseIcon boxSize={"20px"} color={"white"} />
        </Flex>
      </Box>
      <Heading as="h2" size="xl" mt={6} mb={2}>
        {headline}
      </Heading>
      <Text color={"gray.500"}>{children}</Text>
    </Box>
  );
};
