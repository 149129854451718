import { Box, Flex, Heading, HStack, Link, Stack, Text, Textarea, useToast } from "@chakra-ui/react";
import { postRequest } from "API";
import Loading from "components/Loading";
import Navbar from "views/Sections/Navbars/DefaultNavbar";
import { CartItem } from "components/Shop/Cart/CartItem";
import { CartOrderSummary } from "components/Shop/Cart/CartOrderSummary";
import CheckoutModal from "components/Shop/CheckoutModal.js";
import Footer from "views/Sections/Footer";
import { useEffect, useState } from "react";
import Swal from "sweetalert2";
import { getRequest } from "API";

const localCartData = JSON.parse(localStorage.getItem("cart"));
// Assets

function Cart() {
  const [data, setData] = useState([]);
  const [cartPrice, setCartPrice] = useState([]);
  const [cartCoupon, setCartCoupon] = useState({});
  const [deliveriesMethods, setDeliveriesMethods] = useState([]);
  const [delivery, setDelivery] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isBusy, setBusy] = useState(true);
  const [orderAdditionalInfo, setOrderAdditionalInfo] = useState("");

  const toast = useToast();

  const handleOrderAdditionalInfoChange = (e) => {
    const inputValue = e.target.value;
    const truncatedValue = inputValue.slice(0, 500);
    setOrderAdditionalInfo(truncatedValue);
  };

  const handleOpenModal = () => {
    const checkDelivery = deliveriesMethods.filter((item) => item.provider == delivery.provider && item.price == delivery.price);
    if (checkDelivery.length === 0) {
      return toast({
        title: "Wybierz metodę dostawy",
        status: "warning",
        duration: 2000,
        isClosable: false,
      });
    }

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleSubmitForm = async (formData) => {
    handleCloseModal();
    setBusy(true);
    const checkDelivery = deliveriesMethods.filter((item) => item.provider == delivery.provider && item.price == item.price);
    if (!checkDelivery) {
      return toast({
        title: "Wybierz metodę dostawy",
        status: "warning",
        duration: 2000,
        isClosable: false,
      });
    }
    const requestData = {
      userData: formData,
      cart: data,
      coupon: cartCoupon,
      delivery,
      order_additional_info: orderAdditionalInfo,
    };
    try {
      const { returectUri } = await postRequest("public/createOrder", requestData, {
        key: "SUPER_SECRET_TOKEN_BY8ahsda8ogGVHJSBDiuagsad87asdhjabsIDFGs",
      });
      window.location.replace(returectUri);
      localStorage.removeItem("cart");
    } catch (error) {
      console.error(error);
    }
    setBusy(false);
  };

  const removeItem = async (id, color, size) => {
    const cartItem = localStorage.getItem("cart");
    if (cartItem) {
      const existingCart = JSON.parse(cartItem);

      const updatedCartData = existingCart.filter((p) => !(p.id === id && p.size === size && p.color.id === color.id));

      localStorage.setItem("cart", JSON.stringify(updatedCartData));
      setData(updatedCartData);

      const prices = updatedCartData.map((item) => (item.salePrice || item.price) * item.quantity);
      const total = prices.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      setCartPrice(total);
    }
  };

  const onChangeQuantity = async (id, quantity) => {
    const cartItem = localStorage.getItem("cart");
    if (cartItem) {
      const existingCart = JSON.parse(cartItem);
      const updatedCartData = existingCart.map((cartItem) => {
        if (cartItem.id === id) {
          return {
            ...cartItem,
            quantity: parseInt(quantity),
          };
        }
        return cartItem;
      });
      localStorage.setItem("cart", JSON.stringify(updatedCartData));
      setData(updatedCartData);

      const prices = updatedCartData.map((item) => (item.salePrice || item.price) * item.quantity);
      const total = prices.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      setCartPrice(total);
    }
  };

  const addCoupon = () => {
    const localCart = JSON.parse(localStorage.getItem("cart"));
    Swal.fire({
      title: "Dodaj Kupon",
      input: "text",
      inputAttributes: {
        autocapitalize: "off",
      },
      showCancelButton: true,
      confirmButtonText: "Dodaj",
      cancelButtonText: "Anuluj",
      showLoaderOnConfirm: true,
      preConfirm: async (coupon) => {
        const response = await postRequest("public/couponCheck", { cart: localCart, coupon: coupon });

        if (response.error) {
          return Swal.showValidationMessage(response.error);
        }
        if (response.coupon) {
          toast({
            title: `Dodano Kupon ${response.coupon.code}`,
            status: "success",
            duration: 3000,
            isClosable: false,
          });
          setCartCoupon(response.coupon);
          return Swal.close();
        }
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
  };

  const fetchData = async () => {
    try {
      const data = await postRequest(`public/productsCheck`, { cart: localCartData });

      const prices = data.map((item) => (item.salePrice || item.price) * item.quantity);
      const total = prices.reduce((accumulator, currentValue) => accumulator + currentValue, 0);
      setCartPrice(total);
      setData(data);

      const get_delivery_methods = await getRequest(`public/deliveryMethods`);
      setDeliveriesMethods(get_delivery_methods);

      localStorage.setItem("cart", JSON.stringify(data));
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
    const script = document.createElement("script");
    script.src = "https://mapa.apaczka.pl/client/apaczka.map.js";
    script.async = true;
    document.body.appendChild(script);

    setBusy(false);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    localStorage.setItem("delivery", JSON.stringify(delivery));
  }, [delivery]);

  if (isBusy) {
    return (
      <Flex h="100vh">
        <Loading />
      </Flex>
    );
  }

  // Chakra color mode
  return (
    <Box>
      <Navbar />
      <Box
        maxW={{
          base: "3xl",
          lg: "7xl",
        }}
        mx="auto"
        px={{
          base: "4",
          md: "8",
          lg: "12",
        }}
        py={{
          base: "18",
          md: "24",
          lg: "32",
        }}>
        <Stack
          direction={{
            base: "column",
            lg: "row",
          }}
          align={{
            lg: "flex-start",
          }}
          spacing={{
            base: "8",
            md: "16",
          }}>
          <Stack
            spacing={{
              base: "8",
              md: "10",
            }}
            flex="2">
            <Heading fontSize="2xl" fontWeight="extrabold">
              Koszyk ({data.length} przedmiotów)
            </Heading>

            <Stack spacing="6">
              {data?.map((item) => {
                return <CartItem key={item.configId} {...item} onChangeQuantity={onChangeQuantity} onClickDelete={removeItem} />;
              })}
            </Stack>
            <Box>
              <Text>Dodatkowe Informacje o zamówieniu:</Text>
              <Textarea
                size="sm"
                maxW="300px"
                rows={4}
                value={orderAdditionalInfo}
                onChange={handleOrderAdditionalInfoChange}
                placeholder="Dodatkowe Informacje o zamówieniu"
                style={{ resize: "none" }}
              />
            </Box>
          </Stack>

          <Flex direction="column" align="center" flex="1">
            <CartOrderSummary
              price={cartPrice}
              deliveryInfo={delivery}
              setDelivery={setDelivery}
              checkOut={handleOpenModal}
              coupon={cartCoupon}
              addCoupon={addCoupon}
              deliveryMethods={deliveriesMethods}
            />

            <HStack mt="6" fontWeight="semibold">
              <p>lub</p>
              <Link href="/" color={"blue.500"}>
                Wróć do sklepu
              </Link>
            </HStack>
          </Flex>
        </Stack>
      </Box>
      <CheckoutModal isOpen={isModalOpen} onClose={handleCloseModal} delivery={delivery} onSubmit={handleSubmitForm} />
      <Footer />
    </Box>
  );
}

export default Cart;
