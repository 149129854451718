import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import TagManager from "react-gtm-module";

import FrontPage from "views/Pages/FrontPage";
import Cart from "views/Pages/Cart";
import Product from "views/Pages/Product";
import Checkout from "views/Pages/Checkout";
import Continue from "views/Pages/Continue";
import { ChakraProvider } from "@chakra-ui/react";
// Custom Chakra theme
import theme from "theme/theme.js";
import "assets/css/index.css";

const GTM_ID = "G-N3CRW42YSD";

TagManager.initialize({ gtmId: GTM_ID });

ReactDOM.render(
  <ChakraProvider theme={theme} resetCSS={false} strictMode>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<FrontPage />} />
        <Route path="/cart" element={<Cart />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/product/:id" element={<Product />} />
        <Route path="/continue" element={<Continue />} />
        <Route path="*" element={<FrontPage />} />
      </Routes>
    </BrowserRouter>
  </ChakraProvider>,
  document.getElementById("root")
);
