import { Select } from "@chakra-ui/react";
import { getRequest } from "API";
import { useEffect, useState } from "react";

const Categories = ({ onChange }) => {
  const [options, setOptions] = useState([]);
  const [isBusy, setBusy] = useState(true);

  const fetchData = async () => {
    try {
      setBusy(true);
      const data = await getRequest(`public/categories`);
      setOptions(data);
      setBusy(false);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  if (isBusy) {
    return "Loading...";
  }

  return (
    <Select placeholder="Wybierz Kategorie" minW="200px" maxW="200px" p="0" mb="5px" onChange={(e) => onChange(e.target.value)}>
      {options?.map((value, index) => {
        return (
          <option key={index} value={value.configId}>
            {value.name}
          </option>
        );
      })}
    </Select>
  );
};

export default Categories;
