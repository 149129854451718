import { CloseButton, Flex, Link, Select, useColorModeValue } from "@chakra-ui/react";
import { PriceTag } from "./PriceTag";
import { CartProductMeta } from "./CartProductMeta";
import ProductPrice from "../ProductPrice";
const QuantitySelect = (props) => {
  return (
    <Select maxW="64px" aria-label="Select quantity" {...props}>
      <option value="1" style={{ backgroundColor: "#edf2f7" }}>
        1
      </option>
      <option value="2" style={{ backgroundColor: "#edf2f7" }}>
        2
      </option>
      <option value="3" style={{ backgroundColor: "#edf2f7" }}>
        3
      </option>
      <option value="4" style={{ backgroundColor: "#edf2f7" }}>
        4
      </option>
    </Select>
  );
};

export const CartItem = (props) => {
  const { id, isGiftWrapping, name, description, quantity, color, size, image, price, salePrice, onChangeQuantity, onClickDelete } = props;
  return (
    <Flex
      direction={{
        base: "column",
        md: "row",
      }}
      justify="space-between"
      align="center">
      <CartProductMeta name={name} description={description} image={image} isGiftWrapping={false} color={color} size={size} />

      <Flex
        width="full"
        justify="space-between"
        display={{
          base: "none",
          md: "flex",
        }}>
        <QuantitySelect
          value={parseInt(quantity)}
          onChange={(e) => {
            onChangeQuantity(id, parseInt(e.currentTarget.value));
          }}
        />
        <PriceTag price={price} salePrice={salePrice} currency={"PLN"} />
        <CloseButton aria-label={`Delete ${name} from cart`} bg="red.300" border="none" cursor="pointer" onClick={() => onClickDelete(id, color, size)} />
      </Flex>

      {/* Mobile */}
      <Flex
        mt="4"
        align="center"
        width="full"
        justify="space-between"
        display={{
          base: "flex",
          md: "none",
        }}>
        <Link fontSize="sm" textDecor="underline" color="gray.900" onClick={() => onClickDelete(id, color, size)}>
          Delete
        </Link>
        <QuantitySelect
          value={parseInt(quantity)}
          onChange={(e) => {
            onChangeQuantity(id, parseInt(e.currentTarget.value));
          }}
        />
        <PriceTag price={price} salePrice={salePrice} currency={"PLN"} />
      </Flex>
    </Flex>
  );
};
