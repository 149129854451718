import { Stack, Flex, Box, Heading, Text, Button, Image, Icon, useToast } from "@chakra-ui/react";
import { getRequest } from "API";
import Loading from "components/Loading";
import Navbar from "views/Sections/Navbars/DefaultNavbar";
import Footer from "views/Sections/Footer";
import ProductAttributes from "components/Shop/ProductDetails/ProductAttributes";
import { useState, useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import ProductPrice from "components/Shop/ProductPrice";

const Product = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [product, setProduct] = useState(location?.state?.product);
  const [isBusy, setBusy] = useState(!product);
  const [attributes, setAttributes] = useState(null);
  const [similarProducts, setSimilarProducts] = useState([]);
  const [uniqueColors, setUniqueColors] = useState([]);
  const toast = useToast();

  const { id } = useParams();

  const fetchData = async () => {
    try {
      setBusy(true);

      const data = await getRequest(`public/products?id=${id}`);
      const { products } = data;

      setProduct(products[0]);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const fetchSimilarProducts = async () => {
    try {
      const data = await getRequest(`public/products?tags=${product?.tags}&per_page=8`);
      const { products } = data;

      const filteredProducts = products.filter((p) => p.configId !== product?.configId);

      setSimilarProducts(filteredProducts);
      setBusy(false);
    } catch (error) {
      console.log("Error fetching similar products:", error);
    }
  };

  const handleAddToCart = async () => {
    if (uniqueColors.length > 0 && !attributes?.color) {
      return toast({
        title: "Wybierz Kolor",
        status: "warning",
        duration: 200000,
        isClosable: false,
      });
    }

    const cartItem = localStorage.getItem("cart");
    const productJSON = {
      id: product?.configId,
      color: attributes?.color || "",
      size: attributes?.size || "",
      quantity: 1,
    };

    if (cartItem) {
      const existingCart = JSON.parse(cartItem);
      const existingProductIndex = existingCart.findIndex((item) => item.id === productJSON.id && item.color.id == productJSON.color.id && item.size === productJSON.size);

      if (existingProductIndex !== -1) {
        if (existingCart[existingProductIndex].quantity >= 4) {
          return toast({
            title: "Możesz dodać do 4 takich samych przedmiotów",
            status: "warning",
            duration: 2000,
            isClosable: false,
          });
        }
        existingCart[existingProductIndex].quantity += 1;
      } else {
        if (existingCart.length >= 10) {
          return toast({
            title: "Możesz dodać do 10 przedmiotów",
            status: "warning",
            duration: 2000,
            isClosable: false,
          });
        }
        existingCart.push(productJSON);
      }

      localStorage.setItem("cart", JSON.stringify(existingCart));
    } else {
      const updatedCart = [productJSON];
      localStorage.setItem("cart", JSON.stringify(updatedCart));
    }

    Swal.fire({
      title: "<strong>Yay!</strong>",
      icon: "success",
      html: "Dodano produkt do koszyka",
      showCloseButton: true,
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: '<i class="fa fa-cart"></i> Zobacz koszyk!',
      confirmButtonAriaLabel: "",
      cancelButtonColor: "#00b779",
      cancelButtonText: "Kontynuuj zakupy",
    }).then((result) => {
      if (result.isConfirmed) {
        window.location.replace("/cart");
      }
    });
  };

  useEffect(() => {
    if (!product) {
      fetchData();
    } else {
      fetchSimilarProducts();
      const { colors, xs_colors, s_colors, m_colors, l_colors, xl_colors } = product;
      const allColors = [...colors, ...xs_colors, ...s_colors, ...m_colors, ...l_colors, ...xl_colors];
      let uColors = allColors.reduce((uniqueArr, color) => {
        const isDuplicate = uniqueArr.some((uniqueColor) => uniqueColor?.color === color?.color);

        if (!isDuplicate) {
          uniqueArr.push(color);
        }

        return uniqueArr;
      }, []);

      setUniqueColors(uColors);
    }
  }, [id, product]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if (isBusy || !product) {
    return <Loading />;
  }

  const handleClick = (id, similarPro) => {
    navigate(`/product/${id}`, { state: { similarPro } });
    window.location.reload();
  };

  return (
    <Box>
      <Navbar />
      <Box maxW={{ base: "100%", md: "80%" }} mx="auto">
        <Stack align={"center"} spacing={{ base: 8, md: 10 }} py={{ base: 20, md: 28 }} direction={{ base: "column", md: "row" }} mx="auto" justify="center">
          <Flex position={"relative"} rounded={"2xl"} overflow="hidden" align="center" justify="center" w={{ base: "90%", md: "600px" }}>
            <Image alt={product?.name} fit={"cover"} align={"center"} w="100%" src={product?.image} crossOrigin="anonymous" />
          </Flex>
          <Stack flex={1} align={{ base: "center", md: "start" }} w="50%" maxW="800px">
            <Box>
              <Heading lineHeight={1.1} fontWeight={600} fontSize={{ base: "3xl", sm: "4xl", lg: "6xl" }}>
                <Text textAlign={{ base: "center", md: "left" }} mx="auto">
                  {product?.name}
                </Text>

                <ProductPrice
                  price={product.price}
                  salePrice={product.sale_price}
                  fontWeight={500}
                  fontSize={{ base: "xl", sm: "2xl", lg: "3xl" }}
                  align={{ base: "center", md: "left" }}
                  mt={{ base: "-20px", md: "-30px" }}
                />
              </Heading>
            </Box>
            <div color={"gray.500"} dangerouslySetInnerHTML={{ __html: product?.description }} />
            {uniqueColors.length > 0 && (
              <Stack spacing={2} bg="gray.200" p="2" borderRadius={"30px"} minH="100px">
                <ProductAttributes product={product} squareSize="30" setAttributes={setAttributes} />
              </Stack>
            )}

            <Button mt="50px" fontSize="12px" w="200px" bg="red.300" color="#fff" borderColor="#fff" _hover={{ bg: "red.400", borderColor: "red.400", color: "#fff" }} onClick={handleAddToCart}>
              Dodaj do koszyka
            </Button>

            <Flex>
              {product.tags && (
                <Text fontSize="sm" mr="5px">
                  Tagi:{" "}
                </Text>
              )}
              {product?.tags.split(",").map((tag, index) => (
                <Text key={tag} fontSize="sm" color="red.400" mr="5px" cursor="pointer" _hover={{ color: "red.600" }}>
                  {tag.trim()}
                  {index + 1 != product?.tags.split(",").length && ","}
                </Text>
              ))}
            </Flex>
            <Flex mt="-30px">
              {product.categories && (
                <Text fontSize="sm" mr="5px">
                  Kategorie:{" "}
                </Text>
              )}

              {product?.categories.map((category, index) => (
                <Text key={category.id} fontSize="sm" color="red.400" mr="5px" cursor="pointer" _hover={{ color: "red.600" }}>
                  {category.name}
                  {index + 1 != product?.categories.length && ","}
                </Text>
              ))}
            </Flex>
          </Stack>
        </Stack>
        <Heading as="h2" fontSize="2xl" mt={10} mb={"50px"} mx="auto" textAlign={"center"}>
          Similar Products
        </Heading>
        <Stack direction="row" flexWrap={"wrap"} justify={"center"}>
          {similarProducts?.map((similarProduct, index) => {
            return (
              <Flex key={index} borderWidth="1px" borderRadius="lg" overflow="hidden" width="200px" justifyContent={"center"} align="center" flexDirection={"column"}>
                <Image
                  src={similarProduct?.image}
                  alt={similarProduct?.name}
                  width={"150px"}
                  borderRadius={"10px"}
                  cursor="pointer"
                  onClick={() => handleClick(similarProduct?.configId, similarProduct)}
                  crossOrigin="anonymous" 
                />
                <Box p={4} textAlign={"center"} cursor="pointer" onClick={() => handleClick(similarProduct?.configId, similarProduct)}>
                  <Heading as="h3" fontSize="sm" mb={2}>
                    {similarProduct?.name}
                  </Heading>
                  <Text>{similarProduct?.description}</Text>
                  <Text fontSize={"xs"}>{similarProduct?.price} PLN</Text>
                </Box>
              </Flex>
            );
          })}
        </Stack>
      </Box>
      <Footer />
    </Box>
  );
};

export const Blob = (props) => {
  return (
    <Icon width={"100%"} viewBox="0 0 578 440" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.184 439.443c-55.13-5.419-110.241-21.365-151.074-58.767C42.307 338.722-7.478 282.729.938 221.217c8.433-61.644 78.896-91.048 126.871-130.712 34.337-28.388 70.198-51.348 112.004-66.78C282.34 8.024 325.382-3.369 370.518.904c54.019 5.115 112.774 10.886 150.881 49.482 39.916 40.427 49.421 100.753 53.385 157.402 4.13 59.015 11.255 128.44-30.444 170.44-41.383 41.683-111.6 19.106-169.213 30.663-46.68 9.364-88.56 35.21-135.943 30.551z"
        fill="currentColor"
      />
    </Icon>
  );
};

export default Product;
