import React from "react";
// Chakra imports
import { Box } from "@chakra-ui/react";
import Navbar from "views/Sections/Navbars/DefaultNavbar";
import Header from "views/Sections/Header";
import Shop from "views/Sections/Shop";
import Footer from "views/Sections/Footer";
// Assets

function FrontPage() {
  // Chakra color mode
  return (
    <Box>
      <Navbar />
      <Header />
      <Shop />
      <Footer />
    </Box>
  );
}

export default FrontPage;
