import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Center,
  Container,
  Flex,
  Heading,
  Stack,
  Text,
  Input,
  Select,
  HStack,
  useRadioGroup,
  useRadio,
  VStack,
  IconButton,
  Alert,
  AlertIcon,
  useDisclosure,
  AlertTitle,
  AlertDescription,
  CloseButton,
} from "@chakra-ui/react";
import { getRequest } from "API";
import ProductCard from "components/Card/ProductCard";
import PageNumberSelector from "components/PageNumberSelector";
import Categories from "components/Shop/Categories";
import { useDebounce } from "use-debounce";

import { AiOutlineArrowUp } from "react-icons/ai";

function AlertComponent() {
  return (
    <Alert status="warning" w="auto">
      <AlertIcon />
      <Box>
        <AlertTitle>Dłuższy czas wykonywania zamówień!</AlertTitle>
        <AlertDescription>Od 24 lipca do 7 sierpnia z powodu urlopu czas wykonania zamówień może być dłuższy.</AlertDescription>
      </Box>
    </Alert>
  );
}

const ProductList = () => {
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [isBusy, setBusy] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("createdAt");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchValue] = useDebounce(searchTerm, 1000);
  const [sortDirection, setSortDirection] = useState("DESC");

  const fetchData = async (page = 1) => {
    try {
      setBusy(true);
      const queryParams = new URLSearchParams({
        page,
        category: selectedCategory,
        sortDirection,
        sortOrder,
        search: searchValue,
      });
      const data = await getRequest(`public/products?${queryParams}`);
      const { products, pagination } = data;
      setProducts(products);
      setTotalPages(pagination.totalPages);
      setBusy(false);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    fetchData(page);
  };

  const handleUpdateCategory = (category) => {
    console.log(category);
    setCurrentPage(1);
    setSelectedCategory(category);
  };

  const handleSearchChange = (value) => {
    setCurrentPage(1);
    setSearchTerm(value);
    console.log(value);
  };

  const handleChangeSortDirection = () => {
    if (sortDirection === "DESC") {
      return setSortDirection("ASC");
    }
    return setSortDirection("DESC");
  };

  useEffect(() => {
    setCurrentPage(1);
    fetchData(currentPage);
  }, [searchValue, selectedCategory, sortOrder, sortDirection]);

  return (
    <Box>
      <Container maxW="80%" mt="50px">
        <Box textAlign={"center"} mb="30px">
          <Heading fontSize="6xl" color="gray.900">
            SKLEP
          </Heading>
        </Box>

        <Box>
          <Flex direction={{ base: "column", md: "row" }} justify="space-between" align="center" gap="10px" w={{ base: "100%", md: "80%" }} mx="auto">
            <Box>
              <Input
                placeholder="Szukaj..."
                minW="200px"
                maxW="200px"
                py="0"
                border="none"
                value={searchTerm}
                _placeholder={{ paddingLeft: "10px" }}
                onChange={(e) => handleSearchChange(e.target.value)}
              />
            </Box>
            <Box>
              <Categories onChange={handleUpdateCategory} />
              <Flex align={"center"} gap="5px" position="relative">
                <Select placeholder="Sortowanie" value={sortOrder} onChange={(e) => setSortOrder(e.target.value)} w="200px" p="0">
                  <option value="price">Cena</option>
                  <option value="createdAt">Data publikacji</option>
                </Select>
                <IconButton
                  fontSize="20px"
                  color="#000"
                  border="none"
                  aria-label="Change sort direction"
                  size="sm"
                  style={{
                    fontSize: "20px",
                    color: "#000",
                    border: "none",
                    position: "relative",
                    transition: "transform 0.3s",
                    position: "absolute",
                    right: "-40px",
                  }}
                  transform={sortDirection === "DESC" ? "rotate(0deg)" : "rotate(180deg)"}
                  _hover={{ bg: "none" }}
                  icon={<AiOutlineArrowUp />}
                  onClick={handleChangeSortDirection}
                />
              </Flex>
            </Box>
          </Flex>

          <Flex mt="20px" direction="row" gap="30px" flexWrap="wrap" justify="center">
            {products.map((product) => (
              <ProductCard isBusy={isBusy} product={product} key={product.id} />
            ))}
          </Flex>
        </Box>

        <Center mt={4}>
          <PageNumberSelector currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
        </Center>
      </Container>
    </Box>
  );
};

export default ProductList;
