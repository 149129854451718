import { Flex, Box, Link, Text } from "@chakra-ui/react";
import Navbar from "views/Sections/Navbars/DefaultNavbar";
import { useSearchParams } from "react-router-dom";
import { Success, Error } from "components/Results";

export default function Continue() {
  const [searchParams, setSearchParams] = useSearchParams();

  const error = searchParams.get("error");

  return (
    <Box>
      <Navbar fixed={false} />
      <Flex justify={"center"} mt="100px">
        {error ? (
          <Error headline="Oops, coś nie tak">
            <Text>
              Chyba coś pokręciłeś...
              <br />
              Spróbuj złożyć zamówienie ponownie.
            </Text>
            <Link href="/" textDecoration="underline">
              Wróć na stronę główną
            </Link>
          </Error>
        ) : (
          <Success headline="Dziękujemy za zamówienie">
            <Text>Twoje zamówienie jest w drodze do realizacji.</Text>
            <Text>W krótce dostaniesz potwierdzenie zamówienia na maila.</Text>
            <Link href="/" textDecoration="underline">
              Wróć na stronę główną
            </Link>
          </Success>
        )}
      </Flex>
    </Box>
  );
}
