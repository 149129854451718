import { Text } from "@chakra-ui/react";

export default function ProductPrice({ price, salePrice, ...attributes }) {
  return (
    <>
      {salePrice ? (
        <Text fontSize="2xl" fontWeight="bold" color="pink.300" {...attributes}>
          {salePrice} PLN
          <Text as="span" fontSize="lg" fontWeight="normal" color="gray.400" textDecoration="line-through" ml="2">
            {price} PLN
          </Text>
        </Text>
      ) : (
        <Text color="pink.300" fontSize="2xl" m="0" {...attributes}>
          {price} PLN
        </Text>
      )}
    </>
  );
}
