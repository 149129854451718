import { Box, Heading, Container, Text, Button, Flex } from "@chakra-ui/react";
import headerBackground from "assets/img/headerBackground.webp";

const Header = () => {
  return (
    <>
      <Container maxW={"100%"} m="0" p="0">
        <Flex
          justifyContent={"center"}
          alignItems={"center"}
          textAlign={"center"}
          spacing={{ base: 8, md: 14 }}
          h="100vh"
          bgImage={`url(${headerBackground})`}
          bgSize={"cover"}
          bgPosition={"center"}
          animation="fadeIn 2s">
          <Box color="#fff">
            <Heading fontWeight={600} fontSize={{ base: "3xl", sm: "6xl", md: "8xl" }} style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.4)" }}>
              Z Ręki Do Ręki <br />
            </Heading>
          </Box>
        </Flex>
      </Container>
    </>
  );
};

export default Header;
