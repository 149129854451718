import React, { useEffect, useState } from "react";
import { Box, Flex, Tooltip } from "@chakra-ui/react";

const ProductAttributes = ({ product, squareSize = 20, setAttributes }) => {
  const { colors, xs_colors, s_colors, m_colors, l_colors, xl_colors, xxl_colors } = product;

  const [selectedColor, setSelectedColor] = useState(null);
  const [selectedSize, setSelectedSize] = useState(null);
  const [availableColors, setAvailableColors] = useState(null);

  const allColors = [...xs_colors, ...s_colors, ...m_colors, ...l_colors, ...xl_colors, ...xxl_colors];
  let uniqueColors = allColors.reduce((uniqueArr, color) => {
    const isDuplicate = uniqueArr.some((uniqueColor) => uniqueColor?.color === color?.color);

    if (!isDuplicate) {
      uniqueArr.push(color);
    }

    return uniqueArr;
  }, []);

  useEffect(() => {
    if (colors.length > 0) {
      return setAvailableColors(colors);
    }
    const availableColors = uniqueColors.map((color) => {
      color.disabled = true;
      return color;
    });

    setAvailableColors(availableColors);
  }, []);

  function getAvailableColors(size, data) {
    const availableColors = uniqueColors.map((color) => {
      const isColorIncluded = data[`${size.toLowerCase()}_colors`]?.some((dataColor) => dataColor.color === color.color);
      color.disabled = !isColorIncluded;
      return color;
    });

    return availableColors;
  }

  const sizes = [];

  if (xs_colors.length > 0) {
    sizes.push("XS");
  }
  if (s_colors.length > 0) {
    sizes.push("S");
  }
  if (m_colors.length > 0) {
    sizes.push("M");
  }
  if (l_colors.length > 0) {
    sizes.push("L");
  }
  if (xl_colors.length > 0) {
    sizes.push("XL");
  }
  if (xxl_colors.length > 0) {
    sizes.push("XXL");
  }

  const selectSize = (size) => {
    setSelectedSize(size);
    const data = getAvailableColors(size, product);
    setAvailableColors(data);
    setSelectedColor(null);

    setAttributes(() => ({
      size: size,
    }));
  };

  const selectColor = (color) => {
    if (!color.disabled) {
      setSelectedColor(color);

      setAttributes((prevAttributes) => ({
        ...prevAttributes,
        color: color,
      }));
    }
  };

  return (
    <Flex flexDirection={"column"} h="100%" m="auto">
      <Flex mb="10px">
        {availableColors?.map((color, index) => (
          <Tooltip label={color?.name} placement="top" bg="red.200" key={index}>
            <Flex
              width={`${squareSize}px`}
              height={`${squareSize}px`}
              mr="2"
              justify="center"
              align="center"
              borderRadius="50px"
              border={selectedColor === color && "1px solid #000"}
              onClick={() => selectColor(color)}
              key={index}
              position="relative" // Add position: relative to the parent Flex component
            >
              <Box w="80%" h="80%" backgroundColor={color?.color} borderRadius="50px" cursor="pointer" />
              {color.disabled && <Box position="absolute" top="0" left="0" right="0" bottom="0" border="1px" borderColor="rgba(255, 0, 0, 0.5)" borderRadius="50px" pointerEvents="none" />}
            </Flex>
          </Tooltip>
        ))}
      </Flex>
      <Flex>
        {sizes.map((size, index) => (
          <Flex
            key={index}
            border="1px solid"
            bg={selectedSize === size ? "#000" : ""}
            textColor={selectedSize === size ? "#fff" : ""}
            borderColor="#000"
            borderRadius="100px"
            mr="2"
            w={`${squareSize}px`}
            h={`${squareSize}px`}
            fontSize="10px"
            justifyContent="center"
            align="center"
            cursor="pointer"
            _hover={{ bg: "#000", color: "#fff", border: "1px solid #000" }}
            onClick={() => selectSize(size)}>
            {size}
          </Flex>
        ))}
      </Flex>
    </Flex>
  );
};

export default ProductAttributes;
