export const buttonStyles = {
  components: {
    Button: {
      variants: {
        primary: {
          fontSize: "10px",
          bg: "blue.400",
          color: "#fff",
          cursor: "pointer",
          _hover: { bg: "blue.300" },
          _focus: { bg: "blue.300" },
          _active: { bg: "blue.300" },
        },
        navy: {
          fontSize: "10px",
          bg: "navy.900",
          color: "#fff",
          _hover: { bg: "navy.900" },
          _focus: { bg: "navy.900" },
          _active: { bg: "navy.900" },
        },
        "no-effects": {
          _hover: "none",
          _active: "none",
          _focus: "none",
        },
        danger: () => ({
          color: "white",
          bg: "red.500",
          fontSize: "10px",
          _hover: "red.400",
          _focus: "red.400",
          _active: "red.400",
        }),
        outlined: (props) => ({
          color: "red.300",
          bg: "transparent",
          fontSize: "10px",
          border: "1px solid",
          cursor: "pointer",
          _hover: { bg: "red.300", color: "#fff" },
        }),
        dark: (props) => ({
          color: "white",
          bg: "blue.500",
          fontSize: "10px",
          _hover: { bg: "blue.500" },
          _focus: { bg: "blue.600" },
          _active: { bg: "blue.400" },
        }),
        light: (props) => ({
          color: "gray.700",
          bg: "white",
          fontSize: "10px",
          _hover: { bg: "white" },
          _focus: { bg: "white" },
          _active: { bg: "white" },
        }),
      },
      defaultProps: {
        variant: "outlined",
      },
    },
  },
};
