import { Box, Flex, HStack, Icon, Image, Link, Stack, Text, useColorModeValue as mode } from "@chakra-ui/react";
import { FiGift } from "react-icons/fi";

export const CartProductMeta = (props) => {
  const { isGiftWrapping = true, image, name, color, size, description } = props;
  return (
    <Stack direction="row" spacing="5" width="full">
      <Image rounded="lg" width="120px" height="120px" fit="cover" src={image} alt={name} draggable="false" loading="lazy" crossOrigin="anonymous" />
      <Box pt="4">
        <Stack spacing="0.5">
          <Text fontWeight="medium" mb="1">
            {name}
          </Text>
          {size && (
            <Text color="gray.600" fontSize="sm" mb="0">
              Rozmiar: {size}
            </Text>
          )}
          {color && (
            <Flex>
              <Text color="gray.600" fontSize="sm" mt="0">
                Kolor: {color.name}
              </Text>
              <div style={{ marginLeft: "5px", marginTop: "5px", backgroundColor: `${color.color}`, width: "10px", height: "10px", borderRadius: "30px", border: "1px solid #000" }}></div>
            </Flex>
          )}
        </Stack>
        {isGiftWrapping && (
          <HStack spacing="1" mt="3" color={mode("gray.600", "gray.400")}>
            <Icon as={FiGift} boxSize="4" />
            <Link fontSize="sm" textDecoration="underline">
              Add gift wrapping
            </Link>
          </HStack>
        )}
      </Box>
    </Stack>
  );
};
