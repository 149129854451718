import { Box, Image, Text, Card, CardBody, CardFooter, Stack, Heading, Divider, ButtonGroup, Button, IconButton, useToast, Flex } from "@chakra-ui/react";
import Loading from "components/Loading";
import ProductColors from "components/Shop/ProductColors";
import ProductPrice from "components/Shop/ProductPrice";
import ProductSizes from "components/Shop/ProductSizes";
import { BsFillCartPlusFill } from "react-icons/bs";
import { Link, useNavigate } from "react-router-dom";

const ProductCard = ({ isBusy, product, size }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/product/${product.configId}`, { state: { product } });
  };

  const sale_price = -1;

  const price = sale_price || product.sale_price;

  return (
    <Box key={product.id} width={{ base: "100%", lg: "300px" }} marginBottom={4}>
      <Card bg="#fff" color="gray.900" maxW="sm" margin="auto" borderWidth={1} borderRadius="30px" boxShadow="md">
        <CardBody>
          <Box position="relative" overflow="hidden" borderRadius="lg">
            <Box position="relative" overflow="hidden" _hover={{ transform: "scale(1.2)" }} transition="transform 0.2s ease-in-out" borderRadius="lg">
              {isBusy ? (
                <Box h="300px" w="300px">
                  <Loading />
                </Box>
              ) : (
                <Image src={product.image} alt={product.name} loading="lazy" objectFit="cover" borderRadius="lg" w="100%" h="100%" cursor="pointer" onClick={handleClick} crossOrigin="anonymous" />
              )}
            </Box>
          </Box>
          <Stack mt="6" spacing="3" minH="100px">
            <Heading size="md" onClick={handleClick} cursor="pointer">
              {product.name}
            </Heading>
            <Flex direction="column" gap="10px">
              <ProductColors product={product} />
              <ProductSizes product={product} />
            </Flex>
          </Stack>
        </CardBody>
        <Divider />
        <CardFooter>
          <Box ml="auto">
            <ProductPrice price={product.price} salePrice={product.sale_price} />
          </Box>
        </CardFooter>
      </Card>
    </Box>
  );
};

export default ProductCard;
