import { Box, Button, Flex, Heading, Link, Stack, Text, useColorModeValue as mode } from "@chakra-ui/react";
import { FaArrowRight } from "react-icons/fa";
import { formatPrice } from "./PriceTag";
import Swal from "sweetalert2";
import countries from "assets/countries.json";

const OrderSummaryItem = (props) => {
  const { label, sub, value, children } = props;
  return (
    <Box>
      <Flex justify="space-between" fontSize="sm">
        <Box>
          <Text fontWeight="medium" color={mode("gray.600", "gray.400")}>
            {label}
          </Text>
          {sub && (
            <Text fontSize="12px" fontWeight="bold" color={mode("gray.600", "gray.400")}>
              {sub}
            </Text>
          )}
        </Box>
        <Box>
          <Text fontWeight="medium" textAlign={"right"}>
            {value}
          </Text>
          {children}
        </Box>
      </Flex>
    </Box>
  );
};

export const CartOrderSummary = ({ price = 0, deliveryInfo, setDelivery, checkOut, coupon, addCoupon, deliveryMethods }) => {
  const changeDeliveryMethod = () => {
    const d_methods = deliveryMethods
      .map((method) => {
        return `<div><img
          class="swal2-confirm"
          style="background-color:#f2f2f2; padding:10px; cursor:pointer"
          height="50px"
          src="${method.image}"
          alt="${method.name}"
          service="${method.service_id}"
        /><p style="font-size:12px">${method.regions} ${method.regions != "World" ? "only" : ""}</p><p>${method.price} PLN</p></div>`;
      })
      .join("");

    Swal.fire({
      title: "Wybierz Metodę dostawy",
      html: `<div style="display:flex; justify-content:center; gap:10px">${d_methods}</div>`,
      showConfirmButton: false,
      showCancelButton: false,
      customClass: {
        confirmButton: "swal2-hidden",
      },
      didOpen: () => {
        // Add event listeners to the buttons
        const buttons = Swal.getPopup().querySelectorAll(".swal2-confirm");
        buttons.forEach((button) => {
          button.addEventListener("click", () => {
            const selectedValue = button.getAttribute("service");
            const selectedDelivery = deliveryMethods.filter((method) => method.service_id == selectedValue)[0];

            if (selectedDelivery.map === true) {
              const apaczkaMap = new ApaczkaMap({
                app_id: "demo",
                criteria: [{ field: "services_receiver", operator: "eq", value: true }],
                onChange: function (record) {
                  if (record) {
                    setDelivery({ ...selectedDelivery, record, label: `${selectedDelivery.name} ${record.foreign_access_point_id}` });
                  }
                },
              });

              apaczkaMap.setSupplier("INPOST");
              apaczkaMap.setFilterSupplierAllowed(["INPOST"]);
              Swal.close();
              apaczkaMap.show();
            } else {
              setDelivery(selectedDelivery);
              Swal.close();
            }
          });
        });
      },
    });
  };

  let totalPrice = price;
  if (coupon.updatedCart) {
    totalPrice = coupon.updatedCart;
  }
  totalPrice += deliveryInfo?.price || 0;

  let couponSub = "";
  if (typeof coupon !== "undefined" && typeof coupon.code !== "undefined") {
    couponSub = `${coupon.code}: -${coupon.value}${coupon.type == "percentage" ? "%" : "PLN"}`;
  }

  return (
    <Stack spacing="8" borderWidth="1px" rounded="lg" padding="8" width="full" bg="#f2f2f2">
      <Heading size="md">Podsumwanie</Heading>

      <Stack spacing="6">
        <OrderSummaryItem label="Wartość" value={formatPrice(price)} />
        <OrderSummaryItem key={deliveryInfo?.configId} label="Wysyłka" sub={deliveryInfo?.label || deliveryInfo?.name || ""} value={`${formatPrice(deliveryInfo?.price || 0)}`}>
          <Link href="#" textDecor="underline" onClick={changeDeliveryMethod}>
            Wybierz Metodę wysyłki
          </Link>
        </OrderSummaryItem>
        <OrderSummaryItem label="Kupon" sub={couponSub}>
          <Link href="#" textDecor="underline" onClick={addCoupon}>
            {coupon?.code ? "Zmień" : "Dodaj"} kupon
          </Link>
        </OrderSummaryItem>
        <Flex justify="space-between">
          <Text fontSize="lg" fontWeight="semibold">
            Suma
          </Text>
          <Text fontSize="xl" fontWeight="extrabold">
            {formatPrice(totalPrice)}
          </Text>
        </Flex>
      </Stack>
      <Button colorScheme="blue" size="lg" fontSize="md" rightIcon={<FaArrowRight />} onClick={checkOut}>
        Zamówienie
      </Button>
    </Stack>
  );
};
