import { extendTheme } from "@chakra-ui/react";
import { globalStyles } from "./styles";
import { breakpoints } from "./foundations/breakpoints";
import { inputStyles } from "./components/input";
import { buttonStyles } from "./components/button";
import { SelectStyles } from "./components/select";

// import { mode } from "@chakra-ui/theme-tools";
const theme = extendTheme(globalStyles, breakpoints, inputStyles, buttonStyles, SelectStyles);

export default theme;
