import { Box, chakra, Container, Link, SimpleGrid, Stack, Text, VisuallyHidden, Input, IconButton, useColorModeValue, Heading, useToast } from "@chakra-ui/react";
import { ReactNode, useState } from "react";
import { FaInstagram, FaTwitter, FaYoutube } from "react-icons/fa";
import { BiMailSend } from "react-icons/bi";
import { postRequest } from "API";

const SocialButton = ({ children, label, href }) => {
  return (
    <chakra.button
      bg="red.300"
      color="#fff"
      rounded={"full"}
      w={8}
      h={8}
      cursor={"pointer"}
      as={"a"}
      href={href}
      display={"inline-flex"}
      alignItems={"center"}
      justifyContent={"center"}
      transition={"background 0.3s ease"}
      _hover={{
        bg: "red.400",
      }}>
      <VisuallyHidden>{label}</VisuallyHidden>
      {children}
    </chakra.button>
  );
};

const ListHeader = ({ children }) => {
  return (
    <Text fontWeight={"500"} fontSize={"lg"} mb={2}>
      {children}
    </Text>
  );
};

export default function LargeWithNewsletter() {
  const [email, setEmail] = useState("");
  const toast = useToast();

  const handleSubscribe = async () => {
    var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (!email.match(validRegex)) {
      return toast({
        title: "Nieprawidłowy adres email",
        status: "error",
        duration: 2000,
        isClosable: false,
      });
    }

    try {
      const data = await postRequest(`public/subscribe`, { email });

      if (data.error) {
        return toast({
          title: data.error,
          status: "warning",
          duration: 2000,
          isClosable: false,
        });
      }

      return toast({
        title: "Zapisałeś się na newsletter!",
        status: "success",
        duration: 2000,
        isClosable: false,
      });
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  return (
    <Box bg={"default.700"} color={"gray.900"} mt="200px" mb="0">
      <Container as={Stack} maxW={"6xl"} py={10}>
        <SimpleGrid templateColumns={{ sm: "1fr 1fr", md: "2fr 1fr 1fr 2fr" }} spacing={8}>
          <Stack spacing={6}>
            <Box>
              <Heading>Z reki do reki</Heading>
            </Box>
            <Text fontSize={"sm"}>
              © 2023 - Made with love by{" "}
              <Link href="https://github.com/Emsa001/" target="_blank" textDecoration="underline">
                Emanuel
              </Link>
            </Text>
            <Stack direction={"row"} spacing={6}>
              <SocialButton label={"Instagram"} href={"https://www.instagram.com/zreki_doreki1/"}>
                <FaInstagram />
              </SocialButton>
            </Stack>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Informacje</ListHeader>
            <Link href={`${process.env.PUBLIC_URL}/regulamin_zrekidoreki.pdf`} target="_blank" rel="Regulamin pdf">
              Regulamin
            </Link>
            <Link href={`${process.env.PUBLIC_URL}/polityka_prywatnosci_zrekidoreki.pdf`} target="_blank" rel="Polityka prywatności pdf">
              Polityka Prywatności
            </Link>
            <Link href={`${process.env.PUBLIC_URL}/rozmiary.jpg`} target="_blank" rel="Tabela rozmiarów pdf">
              Tabela rozmiarów
            </Link>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Kontakt</ListHeader>
            <Link href={"mailto:kontakt@zrekidoreki.com.pl"}>kontakt@zrekidoreki.com.pl</Link>
            <Link href={"https://www.instagram.com/zreki_doreki1/"}>@zrekidoreki</Link>
          </Stack>
          <Stack align={"flex-start"}>
            <ListHeader>Zapisz się na NewsLetter</ListHeader>
            <Stack direction={"row"}>
              <Input
                placeholder={"Twój email"}
                bg={"blackAlpha.100"}
                border={0}
                color="gray.700"
                _placeholder={{ color: "gray.700" }}
                _active={{ border: "none" }}
                onChange={(e) => setEmail(e.target.value)}
              />
              <IconButton variant="outline" aria-label="Subscribe" icon={<BiMailSend />} cursor="pointer" _hover={{ bg: "gray.400" }} onClick={handleSubscribe} />
            </Stack>
          </Stack>
        </SimpleGrid>
      </Container>
    </Box>
  );
}
