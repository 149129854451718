import React, { useState } from "react";
import { Button, ButtonGroup, Box, useToast } from "@chakra-ui/react";
import { ChevronLeftIcon, ChevronRightIcon } from "@chakra-ui/icons";

const PageNumberSelector = ({ currentPage, totalPages, handlePageChange }) => {
  const [isCooldownActive, setIsCooldownActive] = useState(false);
  const toast = useToast();

  const handlePageClick = (pageNumber) => {
    if (isCooldownActive) {
      toast({
        title: "Cooldown",
        status: "error",
        duration: 1000,
        isClosable: false,
      });
    } else {
      setIsCooldownActive(true);
      handlePageChange(pageNumber);
      setTimeout(() => {
        setIsCooldownActive(false);
      }, 1000);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      handlePageClick(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      handlePageClick(currentPage + 1);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 5;

    let startPage = Math.max(currentPage - Math.floor(maxVisiblePages / 2), 1);
    let endPage = Math.min(startPage + maxVisiblePages - 1, totalPages);

    if (endPage - startPage < maxVisiblePages - 1) {
      startPage = Math.max(endPage - maxVisiblePages + 1, 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(
        <Button
          key={i}
          size="sm"
          variant={i === currentPage ? "solid" : "outline"}
          onClick={() => handlePageClick(i)}
          colorScheme="red"
          textColor={i === currentPage ? "#fff" : "red.200"}
          bg={i === currentPage ? "red.400" : "#fff"}
          cursor="pointer"
          border="1px solid">
          {i}
        </Button>
      );
    }

    return pageNumbers;
  };

  return (
    <Box textAlign="center" mt={4}>
      <ButtonGroup spacing={2}>
        <Button
          size="sm"
          leftIcon={<ChevronLeftIcon />}
          isDisabled={currentPage === 1}
          onClick={handlePreviousPage}
          variant="solid"
          colorScheme="red"
          textColor="#fff"
          cursor="pointer"
          border={"none"}
        />
        {renderPageNumbers()}
        <Button
          size="sm"
          rightIcon={<ChevronRightIcon />}
          isDisabled={currentPage >= totalPages}
          onClick={handleNextPage}
          variant="solid"
          colorScheme="red"
          textColor="#fff"
          cursor="pointer"
          border={"none"}
        />
      </ButtonGroup>
    </Box>
  );
};

export default PageNumberSelector;
