import React, { useEffect, useState } from "react";
import { Box, FormControl, FormLabel, Input, Button, Container, Select } from "@chakra-ui/react";
import { postRequest } from "API";
import Navbar from "views/Sections/Navbars/DefaultNavbar";

const localCartData = JSON.parse(localStorage.getItem("cart"));
const CheckoutPage = () => {
  const [formData, setFormData] = useState({});
  const [cart, setCart] = useState([]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const fetchCart = async () => {
    try {
      const data = localCartData.map((item) => item.id).join(",");

      setCart(data);
    } catch (error) {
      console.log("Error fetching data:", error);
    }
  };

  const handleSubmit = async () => {
    const data = {
      userData: formData,
      cart: cart,
    };
    try {
      const { returectUri } = await postRequest("public/createOrder", data, {
        key: "SUPER_SECRET_TOKEN_BY8ahsda8ogGVHJSBDiuagsad87asdhjabsIDFGs",
      });
      //window.location.replace(returectUri);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchCart();
  }, []);

  return (
    <Box>
      <Navbar fixed={false} />
      <Container maxWidth="lg">
        <Box mt={8}>
          <FormControl>
            <FormControl id="firstName" isRequired>
              <FormLabel>First Name</FormLabel>
              <Input type="text" name="firstName" value={formData.firstName} onChange={handleChange} placeholder="Enter your first name" />
            </FormControl>
            <FormControl id="lastName" isRequired mt={4}>
              <FormLabel>Last Name</FormLabel>
              <Input type="text" name="lastName" value={formData.lastName} onChange={handleChange} placeholder="Enter your last name" />
            </FormControl>
            <FormControl id="country" isRequired mt={4}>
              <FormLabel>Country</FormLabel>
              <Select name="country" value={formData.country} onChange={handleChange} placeholder="Select your country">
                <option value="USA">USA</option>
                <option value="Canada">Canada</option>
                <option value="UK">UK</option>
                {/* Add more country options as needed */}
              </Select>
            </FormControl>
            <FormControl id="city" isRequired mt={4}>
              <FormLabel>City</FormLabel>
              <Input type="text" name="city" value={formData.city} onChange={handleChange} placeholder="Enter your city" />
            </FormControl>
            <FormControl id="postalCode" isRequired mt={4}>
              <FormLabel>Postal Code</FormLabel>
              <Input type="text" name="postalCode" value={formData.postalCode} onChange={handleChange} placeholder="Enter your postal code" />
            </FormControl>
            <FormControl id="address" isRequired mt={4}>
              <FormLabel>Address</FormLabel>
              <Input type="text" name="address" value={formData.address} onChange={handleChange} placeholder="Enter your address" />
            </FormControl>
            <FormControl id="email" isRequired mt={4}>
              <FormLabel>Email</FormLabel>
              <Input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Enter your email" />
            </FormControl>
            <FormControl id="phoneNumber" isRequired mt={4}>
              <FormLabel>Phone Number</FormLabel>
              <Input type="tel" name="phoneNumber" value={formData.phoneNumber} onChange={handleChange} placeholder="Enter your phone number" />
            </FormControl>
            <Button mt={4} colorScheme="teal" type="submit" onClick={handleSubmit}>
              Proceed to Checkout
            </Button>
          </FormControl>
        </Box>
      </Container>
    </Box>
  );
};

export default CheckoutPage;
