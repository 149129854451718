import React from "react";
import { Box, Flex, Tooltip } from "@chakra-ui/react";

const ProductColors = ({ product, squareSize = 20 }) => {
  const { colors, xs_colors, s_colors, m_colors, l_colors, xl_colors } = product;
  let allColors = [];

  allColors = [...colors, ...xs_colors, ...s_colors, ...m_colors, ...l_colors, ...xl_colors];

  let uniqueColors = allColors.reduce((uniqueArr, color) => {
    // Check if the color is already present in the uniqueArr based on the color.color property
    const isDuplicate = uniqueArr.some((uniqueColor) => uniqueColor?.color === color?.color);

    // If it's not a duplicate, add it to the uniqueArr
    if (!isDuplicate) {
      uniqueArr.push(color);
    }

    return uniqueArr;
  }, []);

  return (
    <Flex>
      {uniqueColors.map((color, index) => (
        <Tooltip label={color?.name} placement="top" bg="red.200">
          <Box key={index} border="1px solid #000" borderRadius="100px" width={`${squareSize}px`} height={`${squareSize}px`} backgroundColor={color?.color} mr="2" cursor="pointer" />
        </Tooltip>
      ))}
    </Flex>
  );
};

export default ProductColors;
