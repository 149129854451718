import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, Divider, AbsoluteCenter, ModalFooter, Image } from "@chakra-ui/react";
import { useState } from "react";
import { Input, FormControl, FormLabel, Button, Flex, Box, Select } from "@chakra-ui/react";
import countries from "assets/countries.json";

const CheckoutModal = ({ isOpen, onClose, delivery, onSubmit }) => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [country, setCountry] = useState("");
  const [street, setStreet] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [taxIdentificationNumber, setTaxIdentificationNumber] = useState("");

  const handleFormSubmit = (event) => {
    event.preventDefault();

    // Check for required inputs
    if (!firstName || !lastName || !country || !street || !postalCode || !city || !email || !phoneNumber) {
      return;
    }

    // Check for valid email format
    const emailRegex = /^\S+@\S+\.\S+$/;
    if (!emailRegex.test(email)) {
      return;
    }

    const formData = {
      firstName,
      lastName,
      country,
      street,
      postalCode,
      city,
      email,
      phoneNumber,
      companyName,
      taxIdentificationNumber,
    };
    onSubmit(formData);
  };

  let deliveryArray = delivery?.regions?.split(",");

  return (
    <Modal size="3xl" isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Zamówienie</ModalHeader>
        <ModalBody w={{ base: "80%", md: "89%" }}>
          <form onSubmit={handleFormSubmit}>
            <Box>
              <Flex mb={4} gap="50px">
                <FormControl mr={2} isRequired>
                  <FormLabel htmlFor="firstName">Imie</FormLabel>
                  <Input id="firstName" name="firstName" placeholder="Enter your first name" value={firstName} onChange={(e) => setFirstName(e.target.value)} />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="lastName">Nazwisko</FormLabel>
                  <Input id="lastName" name="lastName" placeholder="Enter your last name" value={lastName} onChange={(e) => setLastName(e.target.value)} />
                </FormControl>
              </Flex>
              <FormControl mb="4" isRequired>
                <FormLabel htmlFor="country">Kraj</FormLabel>
                <Select id="country" name="country" placeholder="Wybierz Kraj" value={country} onChange={(e) => setCountry(e.target.value)}>
                  {delivery?.regions != "World"
                    ? deliveryArray?.map((region, index) => {
                        return (
                          <option key={index} value={region}>
                            {region}
                          </option>
                        );
                      })
                    : countries.map((country) => (
                        <option key={country.code} value={country.name}>
                          {country.name}
                        </option>
                      ))}
                </Select>
              </FormControl>
              <FormControl mb="4" isRequired>
                <FormLabel htmlFor="street">Ulica</FormLabel>
                <Input id="street" name="street" placeholder="Enter your street" value={street} onChange={(e) => setStreet(e.target.value)} />
              </FormControl>
              <Flex gap="50px" mb="4">
                <FormControl isRequired>
                  <FormLabel htmlFor="postalCode">Kod Pocztowy</FormLabel>
                  <Input id="postalCode" name="postalCode" placeholder="Enter your postal code" value={postalCode} onChange={(e) => setPostalCode(e.target.value)} />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="city">Miasto</FormLabel>
                  <Input id="city" name="city" placeholder="Enter your city" value={city} onChange={(e) => setCity(e.target.value)} />
                </FormControl>
              </Flex>
            </Box>
            <Box mt="50px">
              <Flex gap="50px" mb="4">
                <FormControl isRequired>
                  <FormLabel htmlFor="email">Email</FormLabel>
                  <Input id="email" name="email" type="email" placeholder="Enter your email" value={email} onChange={(e) => setEmail(e.target.value)} />
                </FormControl>
                <FormControl isRequired>
                  <FormLabel htmlFor="phoneNumber">Numer Telefonu</FormLabel>
                  <Input id="phoneNumber" name="phoneNumber" placeholder="Enter your phone number" value={phoneNumber} maxLength={16} onChange={(e) => setPhoneNumber(e.target.value)} />
                </FormControl>
              </Flex>
              <Box position="relative" padding="10">
                <Divider />
                <AbsoluteCenter bg="white" px="4" color="gray.600">
                  Opcjonalne
                </AbsoluteCenter>
              </Box>
              <Flex gap="50px" mb="4">
                <FormControl>
                  <FormLabel htmlFor="companyName">Nazwa firmy</FormLabel>
                  <Input id="companyName" name="companyName" placeholder="Enter your company name" value={companyName} onChange={(e) => setCompanyName(e.target.value)} />
                </FormControl>
                <FormControl>
                  <FormLabel htmlFor="taxIdentificationNumber">NIP</FormLabel>
                  <Input
                    id="taxIdentificationNumber"
                    name="taxIdentificationNumber"
                    placeholder="Enter your tax identification number"
                    value={taxIdentificationNumber}
                    maxLength={10}
                    onChange={(e) => setTaxIdentificationNumber(e.target.value)}
                  />
                </FormControl>
              </Flex>
            </Box>
            <ModalFooter>
              <Button mt={4} type="submit" ml="auto" _hover={{ bg: "red.400", color: "#fff" }}>
                Przejdź do płatności
              </Button>
            </ModalFooter>
          </form>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default CheckoutModal;
