export const SelectStyles = {
  components: {
    Select: {
      baseStyle: {
        borderColor: "#fff",
      },
      sizes: {
        md: {
          field: {
            borderRadius: "md",
            bg: "#fff",
            _focus: {
              borderColor: "blue.500",
              boxShadow: "0 0 0 2px rgba(66, 153, 225, 0.6)",
            },
          },
        },
      },
      variants: {
        filled: {
          field: {
            bg: "gray.100",
            color: "#000",
            _placeholder: {
              color: "#000",
            },
            _hover: {
              bg: "gray.100",
            },
            _focusVisible: {
              bg: "gray.100",
            },
          },
        },
      },
      defaultProps: {
        variant: "filled",
      },
    },
  },
};
