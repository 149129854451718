import React from "react";
import { Box, Flex, IconButton, useDisclosure, Collapse, Stack, Tooltip } from "@chakra-ui/react";
import { FaBars, FaShoppingCart } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";

const Navbar = ({ fixed = true }) => {
  const navigate = useNavigate();
  const { isOpen, onToggle } = useDisclosure();

  let position = { base: "static", md: "fixed" };
  if (fixed == false) {
    position = "static";
  }

  return (
    <Box bg="#fff" color="gray.700" px={4} py={3} position={position} w={{ base: "", md: "100%" }} zIndex="999">
      <Box w={{ base: "100%", md: "70%" }} m="auto">
        <Flex alignItems="center">
          <Box fontWeight="bold" fontSize="lg">
            <Link to="/" color="#000">
              Z Reki Do Reki
            </Link>
          </Box>

          <Flex m="auto" display={{ base: "none", md: "flex" }}>
            <Stack direction={{ base: "column", md: "row" }} spacing={{ base: 4, md: 8 }} alignItems="center">
              <Link pe="10px" _hover={{ color: "black", fontWeight: "bold" }}>
                <Tooltip label="Już w krótce">Personalizacja</Tooltip>
              </Link>
            </Stack>
          </Flex>

          <Box ml={{ base: "auto", md: "0" }}>
            <Flex alignItems="center">
              <Flex display={"flex"}>
                <IconButton icon={<FaShoppingCart />} cursor="pointer" variant="outline" colorScheme="orange" aria-label="Shopping cart" onClick={() => navigate("/cart")} />
              </Flex>

              <Flex display={{ base: "flex", md: "none" }} ml="10px">
                <IconButton icon={<FaBars />} onClick={onToggle} aria-label="Open menu" />
              </Flex>
            </Flex>
          </Box>
        </Flex>

        <Flex mt={isOpen && "20px"} transition={"0.5s"}>
          <Collapse in={isOpen} animateOpacity>
            <Stack direction={{ base: "column", md: "row" }}>
              <Link pe="10px" _hover={{ color: "black", fontWeight: "bold" }}>
                <Tooltip label="Już w krótce">Personalizacja</Tooltip>
              </Link>
            </Stack>
          </Collapse>
        </Flex>
      </Box>
    </Box>
  );
};

export default Navbar;
